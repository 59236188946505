<template>
  <div id="camp">
    <el-dialog
      id="videoDialog"
      title="上传视频"
      width="500px"
      :visible.sync="videoDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form inline size="small">
        <el-form-item>
          <el-button type="primary" icon="el-icon-upload2" @click.stop="uploadVideo">
            上传视频
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="albumList"
        row-key="id"
        :cell-style="{ padding: '6px' }"
        :header-cell-style="{ background: '#f8f8f9' }"
        :height="360"
      >
        <el-table-column prop="url" label="名称" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-view"
              size="small"
              @click.stop="videoView(scope.$index, scope.row)"
            >
              查看
            </el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click.stop="videoDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button type="primary" @click="videoDialogVisible = false">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 审核弹框 -->
    <el-dialog
      title="营地信息"
      width="360px"
      :visible.sync="reviewDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="reviewFormRef"
        :model="reviewForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="营地名称">
              <el-input v-model="name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地类型">
              <el-input v-model="type" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地审核" prop="status">
              <el-select v-model="reviewForm.status" placeholder="请选择" clearable>
                <el-option
                  v-for="item in [
                    { label: '通过', value: 1 },
                    { label: '不通过', value: -1 },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="reviewDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reviewFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 新增/修改/详情弹框 -->
    <el-dialog
      :title="campFormTitle"
      width="1200px"
      :visible.sync="campDialogVisible"
      :close-on-click-modal="false"
      @close="campDialogClose"
    >
      <el-form
        ref="campFormRef"
        :model="campForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="营地名称" prop="name">
              <span v-if="campFormTitle === '营地详情'" style="width: 200px">{{ campForm.name || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.name"
                placeholder="请输入营地名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营地地址" prop="adress">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.adress || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.adress"
                placeholder="请输入营地地址"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="营地类型" prop="type">
              <template v-if="campFormTitle === '营地详情'">
                <span v-if="campForm.type === 1">公共营地</span>
                <span v-if="campForm.type === 2">收费营地</span>
                <span v-if="campForm.type === 3">野营地</span>
                <span v-if="campForm.type === 4">酒店营地</span>
              </template>
              <el-select
                v-else
                v-model="campForm.type"
                placeholder="请选择营地类型"
                clearable
              >
                <el-option
                  v-for="item in [
                    {label: '公共营地', value: 1},
                    {label: '收费营地', value: 2},
                    {label: '野营地', value: 3},
                    {label: '酒店营地', value: 4}
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收费标准" prop="chargingStandard">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.chargingStandard || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.chargingStandard"
                placeholder="请输入收费标准"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经度" prop="lng">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.lng || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.lng"
                placeholder="请输入经度"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度" prop="lat">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.lat || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.lat"
                placeholder="请输入纬度"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="审核状态" prop="status">
              <template v-if="campFormTitle === '营地详情'">
                <span v-if="campForm.status === 1">通过</span>
                <span v-else-if="campForm.status === -1">不通过</span>
                <span v-else>{{ '-' }}</span>
              </template>
              <el-radio-group v-else v-model="campForm.status">
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="-1">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="tel">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.tel || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.tel"
                placeholder="请输入联系方式"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地介绍" prop="introduce">
              <span v-if="campFormTitle === '营地详情'">{{ campForm.introduce || '-' }}</span>
              <el-input
                v-else
                v-model="campForm.introduce"
                placeholder="请输入营地介绍"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场地类型">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in cdlxList" :key="item.id" :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地环境">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in ydhjList" :key="item.id" :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="可到车辆">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in kdclList" :key="item.id" :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="配套设施">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in ptssList" :key="item.id" :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地特色">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in ydtsList" :key="item.id" :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地封面">
              <el-upload
                ref="uploadRef"
                action=""
                list-type="picture-card"
                :http-request="handleHttp"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
                :file-list="fileList"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="营地照片">
              <el-image
                v-for="item in fileList2"
                :key="item.id"
                style="width: 150px; height: 150px"
                :src="baseUrl + item.url"
                @click="handlePreview2(item)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="是否显示" prop="display">
              <template v-if="campFormTitle === '营地详情'">
                <span v-if="campForm.display === 1">显示</span>
                <span v-else-if="campForm.display === -1">不显示</span>
                <span v-else>{{ '-' }}</span>
              </template>
              <el-radio-group v-else v-model="campForm.display">
                <el-radio :label="1">
                  显示
                </el-radio>
                <el-radio :label="-1">
                  不显示
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="campFormTitle === '营地详情'">
          <el-col :span="8">
            <el-form-item label="认领情况">
              <span>{{ infoForm.campOwnerStatus || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营地主">
              <span>{{ infoForm.masterName || '-' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="campFormTitle === '营地详情'">
          <el-col :span="4">
            <el-form-item label="营地评分">
              <span>{{ infoForm.score || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="环境">
              <span>{{ infoForm.avgEnv || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="交通">
              <span>{{ infoForm.avgFac || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="设施">
              <span>{{ infoForm.avgSer || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="服务">
              <span>{{ infoForm.avgTra || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="评分数量">
              <span>{{ infoForm.num || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="完善程度">
              <span>{{ infoForm.completeness || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="共建者">
              <span>{{ infoForm.coBuilderList || '-' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="campDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="campFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog id="image" width="540px" :visible.sync="imageDialogVisible">
      <div slot="title">
        <el-button v-if="imageDialogType === 1" type="primary" @click="removeCover">
          移除封面
        </el-button>
        <el-button v-if="imageDialogType === 2" type="primary" @click="asCover">
          设为封面
        </el-button>
      </div>
      <img
        width="500px"
        object-fit="contain"
        :src="imageUrl"
        alt=""
      >
    </el-dialog>

    <!-- 搜索区 -->
    <el-form inline size="small">
      <el-form-item label="营地名称">
        <el-input v-model="searchForm.name" placeholder="请输入营地名称" clearable />
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="searchForm.type" placeholder="请选择类型" clearable>
          <el-option label="公共营地" :value="1" />
          <el-option label="收费营地" :value="2" />
          <el-option label="野营地" :value="3" />
          <el-option label="酒店营地" :value="4" />
        </el-select>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="searchForm.adress" placeholder="请输入地址" clearable />
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker v-model="searchForm.startTime" placeholder="请选择开始时间" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker v-model="searchForm.endTime" placeholder="请选择结束时间" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.status" placeholder="请选择状态" clearable>
          <el-option label="审核通过" :value="1" />
          <el-option label="审核未通过" :value="-1" />
          <el-option label="未审核" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-download" @click="handleOutput">
          导出
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 表格数据 -->
    <el-table
      :data="campPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight() + 51.2 - 102"
    >
      <el-table-column prop="id" label="营地ID" width="80" />
      <el-table-column label="创建日期" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.createTime">{{ scope.row.createTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="营地名称" show-overflow-tooltip />
      <el-table-column label="营地类型" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">公共营地</span>
          <span v-if="scope.row.type === 2">收费营地</span>
          <span v-if="scope.row.type === 3">野营地</span>
          <span v-if="scope.row.type === 4">酒店营地</span>
        </template>
      </el-table-column>
      <el-table-column prop="adress" label="营地地址" show-overflow-tooltip />
      <el-table-column label="是否显示" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.display === 1">显示</span>
          <span v-else>不显示</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status === -1">审核未通过</span>
          <span v-if="scope.row.status === 0">未审核</span>
          <span v-if="scope.row.status === 1">审核通过</span>
        </template>
      </el-table-column>
      <el-table-column label="认领状态" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.campOwnerStatus === 1">已认领</span>
          <span v-else>未认领</span>
        </template>
      </el-table-column>
      <el-table-column label="动态数量" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click.stop="handleMoment(scope.$index, scope.row)">
            {{ scope.row.momentCount }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="$store.state.user.username === 'admin'"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-upload2"
            size="small"
            @click.stop="handleVideo(scope.$index, scope.row)"
          >
            上传视频
          </el-button>
          <el-button
            type="text"
            icon="el-icon-star-off"
            size="small"
            @click.stop="handleMoment(scope.$index, scope.row)"
          >
            动态
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      :total="campPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCamp, deleteCamp, updateCamp, selectCampInfo, selectCampList, review, output,
  removeCover, asCover, addAlbum, deleteAlbum, selectAlbumList } from '@/api/main/camp'
import { selectDictList } from '@/api/system/dict'
import { getUuid } from '@/api/main/file'
import OSS from 'ali-oss'
import Compressor from 'compressorjs'

export default {
  data () {
    return {
      campDialogVisible: false,
      campFormTitle: '',
      cdlxList1: [],
      campForm: {
        id: '',
        name: '',
        type: '',
        adress: '',
        lng: '',
        lat: '',
        status: '',
        tel: '',
        chargingStandard: '',
        introduce: '',
        details: '',
        pic: '',
        display: ''
      },
      infoForm: {},
      campPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        type: '',
        adress: '',
        startTime: '',
        endTime: '',
        status: ''
      },
      reviewDialogVisible: false,
      reviewForm: {
        id: '',
        status: ''
      },
      name: '',
      type: '',
      checkList: [],
      cdlxList: [],
      ydhjList: [],
      kdclList: [],
      ptssList: [],
      ydtsList: [],
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      imageDialogVisible: false,
      imageDialogType: '',
      imageUrl: '',
      albumId: '',
      campId: '',
      fileList: [],
      fileList2: [],
      roleList: [],
      videoDialogVisible: false,
      videoForm: {
        campId: '',
        url: '',
        type: 2,
        sort: ''
      },
      albumList: []
    }
  },
  created () {
    selectCampList(this.searchForm).then((res) => {
      this.campPage = res
    })
    selectDictList(2).then((res) => {
      this.cdlxList = res
    })
    selectDictList(3).then((res) => {
      this.ydhjList = res
    })
    selectDictList(4).then((res) => {
      this.kdclList = res
    })
    selectDictList(5).then((res) => {
      this.ptssList = res
    })
    selectDictList(6).then((res) => {
      this.ydtsList = res
    })
  },
  methods: {
    // 点击搜索触发的回调
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCampList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    // 当前页发生改变触发的回调
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCampList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    // 每页展示的数据发生改变触发的回调
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCampList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    // Dialog 关闭的回调
    campDialogClose () {
      this.$refs.campFormRef.resetFields()
      this.checkList = []
      this.fileList = []
      this.fileList2 = []
    },
    // 新增/修改"确定"触发的回调
    campFormSubmit () {
      if (this.campFormTitle === '营地详情') {
        this.campDialogVisible = false
        return
      }
      this.$refs.campFormRef.validate(async (valid) => {
        if (valid) {
          this.campForm.details = this.checkList.join(',')
          this.campForm.pic = this.fileList.map((item) => item.name).join(',')
          if (this.campFormTitle === '新增营地') {
            await addCamp(this.campForm)
          } else if (this.campFormTitle === '修改营地') {
            await updateCamp(this.campForm)
          }
          this.campPage = await selectCampList(this.searchForm)
          this.campDialogVisible = false
        }
      })
    },
    // 审核"确定"触发的回调
    reviewFormSubmit () {
      this.$refs.reviewFormRef.validate(async (valid) => {
        if (valid) {
          await review(this.reviewForm)
          this.campPage = await selectCampList(this.searchForm)
          this.reviewDialogVisible = false
        }
      })
    },
    // 点击审核触发的回调
    handleReview (index, row) {
      this.reviewDialogVisible = true
      this.reviewForm.id = row.id
      this.name = row.name
      if (row.type === 1) {
        this.type = '公共营地'
      } else if (row.type === 2) {
        this.type = '收费营地'
      } else if (row.type === 3) {
        this.type = '野营地'
      } else {
        this.type = '酒店营地'
      }
      if (row.status != 0) {
        this.reviewForm.status = row.status
      } else {
        this.reviewForm.status = ''
      }
    },
    // 点击删除触发的回调
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCamp({ id: row.id })
        if (this.campPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.campPage = await selectCampList(this.searchForm)
      })
    },
    // 点击新增触发的回调
    handleAdd () {
      this.campFormTitle = '新增营地'
      this.campDialogVisible = true
    },
    // 点击修改触发的回调
    handleUpdate (index, row) {
      this.campFormTitle = '修改营地'
      this.campDialogVisible = true
      this.campId = row.id
      this.selectCampInfoById(row.id)
    },
    // 点击详情触发的回调
    handleInfo (index, row) {
      this.campFormTitle = '营地详情'
      this.campDialogVisible = true
      this.campId = row.id
      this.selectCampInfoById(row.id)
      this.$nextTick(() => {
        this.cdlxList.forEach((item2) => {
          this.checkList.forEach((item) => {
            if (item2.id == item) {
              this.cdlxList1.push(item2.label)
            }
          })
        })
      })
    },
    handleMoment (index, row) {
      this.$router.push('/moments/' + row.id)
    },
    selectCampInfoById (id) {
      selectCampInfo(id).then((res) => {
        this.campForm.id = res.id
        this.campForm.name = res.name
        this.campForm.type = res.type
        this.campForm.adress = res.adress
        this.campForm.lng = res.lng
        this.campForm.lat = res.lat
        this.campForm.status = res.status
        this.campForm.tel = res.tel
        this.campForm.chargingStandard = res.chargingStandard
        this.campForm.introduce = res.introduce
        if (res.dict) {
          const dict = res.dict.split(',')
          this.checkList = []
          for (let i = 0; i < dict.length; i++) {
            this.checkList.push(parseInt(dict[i]))
          }
        }
        // if (res.pic) {
        //   const picUrl = res.pic.split(',')
        //   this.fileList = []
        //   for (let i = 0; i < picUrl.length; i++) {
        //     this.fileList.push({
        //       name: picUrl[i],
        //       url: this.baseUrl + picUrl[i]
        //     })
        //   }
        // }
        if (res.albumList1.length > 0) {
          this.fileList = []
          for (let i = 0; i < res.albumList1.length; i++) {
            this.fileList.push({
              name: res.albumList1[i].url,
              url: this.baseUrl + res.albumList1[i].url,
              id: res.albumList1[i].id
            })
          }
        }
        if (res.albumList2.length > 0) {
          this.fileList2 = []
          for (let i = 0; i < res.albumList2.length; i++) {
            this.fileList2.push(res.albumList2[i])
          }
        }
        this.campForm.display = res.display

        this.infoForm = {}
        if (res.isClaim === 1) {
          this.infoForm.isClaim = '已认领'
        } else {
          this.infoForm.isClaim = '未认领'
        }
        this.infoForm.masterName = res.masterName
        this.infoForm.score = res.score
        this.infoForm.avgEnv = res.campScores.avgEnv
        this.infoForm.avgFac = res.campScores.avgFac
        this.infoForm.avgSer = res.campScores.avgSer
        this.infoForm.avgTra = res.campScores.avgTra
        this.infoForm.num = res.campScores.num
        this.infoForm.completeness = res.completeness + '%'
        this.infoForm.coBuilderList = res.coBuilderList.map((item) => item.name).join(',')
      })
    },
    // 	覆盖默认的上传行为，可以自定义上传的实现
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)

      let that = this
      new Compressor(file.file, {
        quality: 0.2,
        success (res) {
          const fileName = file.file.name
          const ext = fileName.substring(fileName.indexOf('.'))

          const client = new OSS({
            // endpoint: 'oss-quye.huahuihongxin.com',
            secure: true,
            region: 'oss-cn-hangzhou',
            accessKeyId: 'LTAI5tBeAND7QEx1z9TJiQU3',
            accessKeySecret: 'MYCiGFWkNJqgPhCM5IdXS5LxNiWcDA',
            bucket: 'oss-quye'
          })

          async function put () {
            try {
              const uuid = await getUuid()
              const newFileName = uuid + ext
              const result = await client.put(newFileName, res)
              console.log(result)
              that.fileList.push({
                name: newFileName,
                url: that.baseUrl + newFileName,
                uid: file.file.uid
              })
            } catch (e) {
              console.log(e)
            }
          }

          put()
        },
        error (err) {
          console.log(err.message)
        }
      })

      // upload2(data).then(res => {
      //   this.fileList.push({
      //     name: res,
      //     url: this.baseUrl + res,
      //     uid: file.file.uid
      //   })
      // })
    },
    // 文件列表移除文件时的钩子
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview (file) {
      this.imageUrl = file.url
      this.imageDialogType = 1
      this.albumId = file.id
      this.imageDialogVisible = true
    },
    handlePreview2 (item) {
      this.imageUrl = this.baseUrl + item.url
      this.imageDialogType = 2
      this.albumId = item.id
      this.imageDialogVisible = true
    },
    removeCover () {
      removeCover({ id: this.albumId }).then(res=>{
        this.selectCampInfoById(this.campId)
        this.imageDialogVisible = false
      })
    },
    asCover () {
      // for (var i = 0; i < this.fileList.length; i++) {
      //   if (this.fileList[i].url == this.imageUrl) {
      //     this.fileList.splice(0, 0, this.fileList[i])
      //     this.fileList.splice(i + 1, 1)
      //     break
      //   }
      // }
      asCover({ id: this.albumId }).then(res=>{
        this.selectCampInfoById(this.campId)
        this.imageDialogVisible = false
      })
    },
    handleOutput () {
      output(this.searchForm).then(res => {
        const url = URL.createObjectURL(res)
        const link = document.createElement('a')
        link.href = url
        link.download = '营地.xls'
        link.click()
        URL.revokeObjectURL(res)
      })
    },
    handleVideo (index, row) {
      this.videoDialogVisible = true
      this.videoForm.campId = row.id
      this.albumList = []
      selectAlbumList({ campId: row.id, type: 2 }).then(res => {
        this.albumList = res.list
      })
    },
    uploadVideo () {
      const input = document.createElement('input')
      input.type = 'file'
      input.onchange = () => {
        var file = input.files[0]
        const fileName = file.name
        const ext = fileName.substring(fileName.indexOf('.'))

        const client = new OSS({
          // endpoint: 'oss-quye.huahuihongxin.com',
          secure: true,
          region: 'oss-cn-hangzhou',
          accessKeyId: 'LTAI5tBeAND7QEx1z9TJiQU3',
          accessKeySecret: 'MYCiGFWkNJqgPhCM5IdXS5LxNiWcDA',
          bucket: 'oss-quye'
        })
        let that = this
        async function put () {
          try {
            const uuid = await getUuid()
            const newFileName = uuid + ext
            that.videoForm.url = newFileName
            await addAlbum(that.videoForm)
            client.put(newFileName, file)
            selectAlbumList({ campId: that.videoForm.campId, type: 2 }).then(res => {
              that.albumList = res.list
            })
          } catch (e) {
            console.log(e)
          }
        }

        put()
      }
      input.click()
    },
    videoDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        deleteAlbum(row.id).then(res => {
          selectAlbumList({ campId: row.id, type: 2 }).then(res => {
            this.albumList = res.list
          })
        })
      })
    },
    videoView (index, row) {
      const a = document.createElement('a')
      a.href = this.baseUrl + row.url
      a.target = '_blank'
      a.click()
    }
  }
}
</script>

<style>
#camp #image .el-dialog__body {
  padding: 0px 20px 20px 20px;
}
#camp #videoDialog .el-dialog__body {
  padding: 0px 20px 20px 20px;
}
</style>
